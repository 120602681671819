import Header from '../components/Header'
import { Link } from 'react-router-dom'
import {Row, Col} from 'react-bootstrap'
import '../../src/assets/styles/about.css'

const About = () => {
   const primaryText = "Melissa Shields"
  const secondaryText = "Bitterroot Valley & Beyond"
  const thirdText = "Real Estate Broker"
  const button =
    <Link to="/listings" className="btn btn-warning py-2 px-5">
      Search Listings
    </Link>
  return (
    <>
      <Header primary={primaryText} secondary={secondaryText} thirdText={thirdText} button={button} className="header"/>
      <Row className="about-container p-0">
        <Col md="7" sm="12">
          <img src="/images/about/crew-transformed.png" alt="Melissa_Shields Family on DirtBikes" className="w-100 border-bottom border-warning" />
        </Col>
        <Col md="5" sm="12" className="text-center text-light">
          <h1 className="mt-5 text-warning">Meet my Crew!</h1>
          <p className="mt-5 mb-5">Well, How about we start here!</p>
          <p className="mb-5 col-md-9 col-12 mx-auto">This is my support, my circle, my "CREW"... My family! Yup! I have a big family. My husband (Owner of Shields Electric) I absolutely enjoy time spent with the crew! So, outside of all the work we do, we play as often as time affords us the ability to.</p>
          <p className="col-md-9 col-12 mx-auto">We live in a Beautiful state. From time-to-time, the crew goes out to explore this beauty in its essence. We enjoy Dirt Biking, side-by-siding, camping. Sometimes there's a debate about whether skiing is better than snowboarding! Whatever we settle on, we enjoy it all!</p>
          <hr className="text-warning mb-3" />

          <Link to={"https://www.facebook.com/eshieldselectric/"} className="btn btn-warning px-5 py-2">
            Shields Electric
          </Link>
        </Col>
        <Col md="5" sm="12" className="text-center text-light">
          <h1 className="mt-5 text-warning">
            Who's Melissa Shields?
            <span>
              <p className="mt-5 mb-5">Many Hats? Understatement!</p>
            </span>
          </h1>

          <p className="mb-5 col-md-9 col-12 mx-auto">Mom, Wife, Board Member, Community Member, Contributor, Business Owner, Life Coach, Broker! Yeah... Understatement!</p>

          <p className="col-md-9 col-12 mx-auto">The truth is, I've been serving the Bitterroot Valley and Surrounding Communities for over 10 years. As your broker, I am a community member first, looking out for your best interests at heart. My goal goes beyond just securing your next house, I aim to find you a home!</p>

          <p className="col-md-9 col-12 mx-auto">I am invested in my community because I am invested in you! I aspire to help you expand your investments as a homeowner and a community member. I do so by further providing detailed, helpful and inspiring life coaching sessions to help you thrive and embrace your worthiness to enhance your potential and maximize your abilities!</p>
          <hr className="text-warning mb-3" />

          <Link to={"https://www.thrivingwithmelissa.com/"} className="btn btn-warning px-5 py-2">
            Thrive With Me!
          </Link>
        </Col>

        <Col md="7" sm="12">
          <img src="/images/about/smiles-transformed.jpeg" alt="Melissa_Shields and Husband Smiling" className="w-100 border-top border-warning" />
        </Col>
      </Row>
      <Row></Row>
    </>
  )
}

export default About
