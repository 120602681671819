import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import Header from "../components/Header"
import PersonalListings from "../components/PersonalListings"
// import Loader from "../components/Loader"
import { baseUrl } from "../utils/baseUrl"
import NotFound from "../components/NotFound"

const Home = () => {
  const [listings, setListings] = useState([])
  // const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getListings = async () => {
      // setIsLoading(true)
      try {
        const { data } = await axios(baseUrl)
        const listings = data.map(obj => {
          const newMedia = obj.Media.filter(media => media.MediaCategory === "Photo")
          return { ...obj, Media: newMedia }
        })

        setListings(listings)
      } catch (err) {
        if (err) {
          return <NotFound />
        }
      }
      // setIsLoading(false)
    }

    getListings()
  }, [])

  const primaryText = "Melissa Shields"
  const secondaryText = "Bitterroot Valley & Beyond"
  const thirdText = "Real Estate Broker"
  const videoSource = "video/background_video.mp4"
  const button = (
    <Link to="/listings" className="btn btn-warning py-2 px-5">
      Search Listings
    </Link>
  )
  return (
    <>
      <Header primary={primaryText} secondary={secondaryText} thirdText={thirdText} button={button} />
      <video muted autoPlay loop playsInline>
        <source src={videoSource} type="video/mp4" />
      </video>
      <PersonalListings data={listings} />
    </>
  )
}

export default Home
