import { Row, Col, Container } from "react-bootstrap"

const Alternative = () => {
  return (
    <Container>
      <Row className="mx-auto">
        <Col lg={12} className="vh-100 mx-auto text-center">
          <iframe className="shadow shadow-lg rounded rounded-lg" src="https://mtr.mlsmatrix.com/Matrix/public/IDX.aspx?idx=72c7c8" style={iframeStyle} allowFullScreen={true} ></iframe>
        </Col>
      </Row>
    </Container>
  )
}

 const iframeStyle = {
   width: "100%",
   marginLeft: "auto",
   height: "100%",
   border: "none",
 }

export default Alternative
