import Header from "../components/Header"
import { Row, Col } from "react-bootstrap"
import { resources } from "../shared/resourceData"
import { FaFacebook, FaEnvelope, FaPhoneAlt } from "react-icons/fa"
import { Link } from "react-router-dom"

const Resources = () => {
  const primaryText = "Melissa Shields"
  const secondaryText = "Live & Thrive in the Bitterroot"
  const thirdText = "Bitterroot Resources"
  const button = (
    <Link to="/listings" className="btn btn-warning py-2 px-5">
      Search Listings
    </Link>
  )
  return (
    <>
      <Header primary={primaryText} secondary={secondaryText} thirdText={thirdText} button={button} />
      <video muted autoPlay loop playsInline>
        <source src="video/farmers.mp4" type="video/mp4" />
      </video>
      <Row className="text-center p-4 align-items-center mt-5">
        {resources.map(res => (
          <Col key={res.id} md="3" sm="12" className="mb-5 mx-auto">
            <Col className="mx-auto shadow" md={12}>
              <img src={res.images} alt={res.name} className="border-bottom border-warning" style={{ height: "300px", width: "100%", objectFit: "cover" }} />
              <div className="p-2">
                <h4 className="mt-2">{res.name}</h4>
                <p className="border-bottom border-warning mt-4 py-2">{res.mission}</p>
                <Link to={res.facebook} className="col-6 text-center display-6">
                  <FaFacebook className="text-primary" />
                </Link>
                &nbsp; &nbsp;
                <Link to={res.email} className="text-center display-6">
                  <FaEnvelope className="text-warning" />
                </Link>
                <div className=" mt-3 mb-3 border-top py-3">
                  {res.phone ? (
                    <span>
                      <FaPhoneAlt/> {res.phone}
                    </span>
                  ) : null}
                </div>
                <Link to={res.website} className="btn btn-dark mb-2">
                  {res.name}
                </Link>
              </div>
            </Col>

            {/* <Card style={{ height: "55rem" }} className="border-0 shadow shadow-lg">
              <Card.Img src={res.images} style={{ height: "500px", width: "100%", objectFit: "cover" }} className="border-bottom" alt={`icon for ${res.name}`} />
              <Card.Body className="p-5">
                <Card.Title>{res.name}</Card.Title>
                <Card.Text className="border-bottom border-warning p-3">
                  <p className="border-bottom border-warning p-2">{res.mission}</p>
                  <Link to={res.facebook} className="col-6 text-center display-6">
                    <FaFacebook className="text-primary" />
                  </Link>
                  &nbsp; &nbsp;
                  <Link to={res.email} className="col-6 text-center display-6">
                    <FaEnvelope className="text-warning" />
                  </Link>
                </Card.Text>
                <Card.Text>
                  {res.phone ? (
                    <span>
                      {" "}
                      <FaPhoneAlt /> {res.phone}
                    </span>
                  ) : null}
                </Card.Text>

                <Card.Text>
                  <Link to={res.website} className="btn btn-dark">
                    {res.name}
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card> */}
          </Col>
        ))}
      </Row>
    </>
  )
}

export default Resources
