import { Row, Col } from "react-bootstrap"
import NavMenu from "./NavMenu"


const Header = props => {
  const { primary, secondary, thirdText, button, className, style } = props
  return (
    <header className={className} style={style}>
      <NavMenu />
      <Row className="h-100 align-items-center">
        <Col sm={12} className="text-center my-auto my-md-auto">
          <p className="text-light text-center" style={{zIndex:2}}>{secondary}</p>
          <h1 className="text-light text-center mb-3 mt-5" style={{zIndex:2}}>{primary}</h1>
          <p className="text-light text-center" style={{zIndex:2}}>{thirdText}</p>
          {button}
        </Col>
      </Row>
    </header>
  )
}

export default Header
