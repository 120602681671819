import { Row, Col } from 'react-bootstrap'
import ContactForm from './ContactForm'

const Footer = () => {
  const date = new Date()
  return (
    <Row className="contact-form-container mt-5" >
      <Col md={12} className="text-center text-light mt-5">
        <h1>Let's Connect!</h1>
        <p>Complete the form below to get in touch!</p>
      </Col>
      <ContactForm />
      <Col md={12} className="text-center text-light mt-5">
        <h5>Melissa Shields Montana</h5>
        <p>Developed by Kwanele Khumalo, Digital Circle</p>
        <p>&copy;{date.getFullYear() }</p>
      </Col>
    </Row>
  )
}

export default Footer
