import { Carousel, Col } from "react-bootstrap"
import {Reviews} from '../shared/Reviews'

const Slide = () => {
  return (
    <>
      <Carousel variant="light" className="text-center bg-dark text-light shadow shadow-lg home-slide w-100 mt-5 p-5">
        {Reviews.map(review => (
          <Carousel.Item interval={5000} className="mb-5 align-items-center" key={review.id}>
            <h3 className="mb-4 mt-4">{review.name}</h3>
            <Col md={8} className="text-center mx-auto mt-5 mb-5 px-3 d-flex" style={{ height: "20vh" }}>
              <p style={{margin: "auto"}}>{review.review}</p>
            </Col>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  )
}

export default Slide
