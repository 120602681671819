export const resources = [
  {
    id: "1",
    name: "BVBOR",
    mission: "The mission of the Bitterroot Valley Board of Realtors® is: To empower REALTORS® as they preserve, protect and advance the right to real property for all.",
    facebook: "https://www.facebook.com/BVBOR/",
    email: "admin@bvbor.org",
    phone: "406-363-2000",
    website: "https://www.bvbor.org/",
    images: "images/icons/bvbor.png"
  },
  {
    id: "2",
    name: "HAMILTON MORTGAGE SERVICES",
    mission: "Our goal is to provide home loans to our clients while providing them with the lowest interest rates and closing costs possible.",
    facebook: "https://www.facebook.com/profile.php?id=100063644420445",
    email: "angela@hamiltonmortgageloans.com",
    phone: "(406) 375-8800",
    website: "https://www.hamiltonmortgageloans.com/",
    images: "/images/icons/Real estate Investment.png"
  },
  {
    id: "3",
    name: "FISH, WILDLIFE & PARKS",
    mission: "Locate Montana's 55 state parks offer an endless array of opportunity and connection outside. Get your licenses and permits for fishing, hunting & floating.",
    facebook: "https://www.facebook.com/MontanaFWP",
    email: "https://public.govdelivery.com/accounts/MTFWP/subscriber/new",
    phone: "(406) 444-2535",
    website: "https://fwp.mt.gov/",
    images: "/images/icons/CampingAdventure1-01.jpg"
  },
  {
    id: "4",
    name: "AIR QUALITY",
    mission: "Montana specific site that provides information on hourly levels of fine particulate matter (main pollutant of concern in wildfire smoke) across the state.",
    facebook: "https://www.facebook.com/climatesmartmissoula",
    email: "info@climatesmartmissoula.org",
    phone: "406-258-3600",
    website: "https://www.montanawildfiresmoke.org/todays-air.html",
    images: "/images/icons/air.jpg"
  },
  {
    id: "5",
    name: "CHAMBER OF COMMERCE",
    mission: "Non-profit organization, dedicated to representing each and every one of its members while amplifying the collective voice of business in an effort to advocate economic vitality, quality of life and the preservation of the competitive enterprise system.",
    facebook: "https://www.facebook.com/Bitterroot-Valley-Chamber-of-Commerce-103451483020846/",
    email: "https://melissashieldsmt.com/localinfo@bvchamber.com",
    phone: "406-363-2400",
    website: "https://bitterrootchamber.com/",
    images: "/images/icons/chamber.png"
  },
  {
    id: "6",
    name: "onX Maps",
    mission: "OnX aims to awaken the adventurer inside everyone. onX Enables you to discover more opportunities to recreate and explore on public and other accessible lands.",
    facebook: "https://www.facebook.com/onxhunt",
    email: "https://public.govdelivery.com/accounts/MTFWP/subscriber/new",
    phone: "",
    website: "https://www.onxmaps.com/",
    images: "/images/icons/onX.png"
  },
  {
    id: "7",
    name: "THRIVE WITH MELISSA",
    mission: "Helpful and inspiring life coaching sessions to help you thrive and embrace your worthiness to enhance your potential and maximize your abilities.",
    facebook: "https://www.facebook.com/melissashieldsmt",
    email: "melissashieldsmt@gmail.com",
    phone: "",
    website: "https://www.thrivingwithmelissa.com/",
    images: "/images/icons/thrive.png"
  },
  {
    id: "8",
    name: "RAVALLI COUNTY MUSEUM",
    mission: "The museum aims to acquire, preserve, protect and interpret the historical and cultural heritage of the Bitter Root Valley and its people. Ravalli County Museum is operated and supported by the Bitter Root Valley Historical Society.",
    facebook: "https://www.facebook.com/pages/Ravalli-County-Museum/210091763266",
    email: "museum@ravallimuseum.org",
    phone: "406-363-3338",
    website: "https://ravallimuseum.org/",
    images: "/images/icons/museum.jpg"
  }
]