import { Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FaBed, FaShower, FaHome } from "react-icons/fa"
import Slide from "./Slide"
import NotFound from "./NotFound"
import Alternative from "./Alternative"

const PersonalListings = props => {
  const { data } = props
  return (
    <>
      <Row className="featured-properties-container justify-content-round mt-5 p-2">
        <h1 className="text-center text-light mb-5 mt-5">Featured Listings</h1>
        {data.length > 0 ? data.map(listing => (
          <Col sm={12} md={6} lg={5} xl={4} key={listing.ListingId} className="mt-3 text-center mb-5">
            <Col className="bg-light rounded mx-auto" md={12} lg={8}>
              <img src={listing.Media[0].MediaURL} alt={listing.StreetName} style={{ width: "100%", height: "20rem", objectFit: "cover" }} />
              <div className="text-center border-bottom border-warning">
                <h3 className="mt-2">{`${listing.StreetNumber} ${listing.StreetName}, ${listing.City}`}</h3>
                {listing.MlsStatus === "Active" ? <p className="text-success">{`${listing.MlsStatus.replace(/([A-Z])/g, " $1").trim()}`}</p> : <p className="text-warning">{`${listing.MlsStatus.replace(/([A-Z])/g, " $1").trim()}`}</p>}
              </div>

              <div className="text-center row mt-1">
                <Col md={6}>
                  <FaBed size={30} />
                </Col>
                <Col md={6}>{listing.PropertyType === "Land" ? (listing.BedroomsTotal = "0") : listing.BedroomsTotal}</Col>
              </div>

              <div className="text-center row mt-1">
                <Col md={6}>
                  <FaShower size={30} />
                </Col>
                <Col md={6}>{listing.PropertyType === "Land" ? (listing.BathroomsFull = "0") : listing.BathroomsFull}</Col>
              </div>

              <div className="text-center row mt-1">
                <Col md={6}>
                  <FaHome size={30} />
                </Col>
                <Col md={6}>{listing.PropertyType}</Col>
              </div>

              <div className="text-center row">
                <Col md={12}>
                  <Link to={`/listing/${listing.ListingKey}`} className="btn btn-warning mb-3 w-50 mx-auto mt-2 text-light fw-bold">
                    View More
                  </Link>
                </Col>
                <Col md={12} className="mb-3 fw-bold">{`List Price: $${listing.ListPrice.toLocaleString("ru-RU")}.00`}</Col>
              </div>
            </Col>
          </Col>
        )) : <Alternative />}
      </Row>
      <Slide />
    </>
  )
}

export default PersonalListings
