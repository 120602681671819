import { Navbar, Nav} from "react-bootstrap"
import { Link } from "react-router-dom"
import { FaBars } from "react-icons/fa"

const NavMenu = () => {
  return (
    <Navbar expand="md" collapseOnSelect className="px-md-5 nav-menu position-absolute top-0 w-100">
      <Link className="navbar-brand" to="/">
        <img src="/images/logo/Logo-white.png" width="80" alt="Melissa_Shields_Logo" />
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" className="border-0">
        <FaBars color="white" size={40} />
      </Navbar.Toggle>

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto text-center">
          <Link className="nav-link text-light" to="/">
            HOME
          </Link>
          <Link className=" nav-link text-light" to="/about">
            ABOUT
          </Link>
          <Link className="text-light nav-link " to="/listings">
            PROPERTY LISTINGS
          </Link>
          <Link className="nav-link text-light" to="/resources">
            HELPFUL RESOURCES
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavMenu
