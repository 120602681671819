import { Form, Button, Row, Col } from "react-bootstrap"
import { useState } from "react"

const QueryForm = ({ setFormData, setBaths, setBeds }) => {
  const [city, setCity] = useState("")
  const [bed, setBed] = useState("")
  const [bath, setBath] = useState("")

  const handleInput = e => {
    const { value } = e.target
    const convertValue = value.toLowerCase();
    setCity(convertValue.charAt(0).toUpperCase() + convertValue.slice(1))
  }

  const handleBed = e => {
    setBed(e.target.value)
  }

  const handleBath = e => {
    setBath(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setFormData(city)
    setBaths(bath)
    setBeds(bed)
  }

  return (
    <Form onSubmit={handleSubmit} className="mx-auto mb-5">
      <Form.Group as={"div"} className="mb-2 mx-auto p-5 text-center" controlId="city" style={{ gap: "20px" }}>
        <Row className="mx-auto text-center p-3 align-items-center">
          <Col md={4} lg={3} sm={8} className="mx-auto mb-3 d-flex flex-column justify-content-around align-items-center ">
            <Form.Label className="text-light px-2 my-auto mb-2">CITY/TOWN: </Form.Label>
            <Form.Control onChange={handleInput} autoComplete="off" type="text" name="city" placeholder="City" value={city} />
          </Col>

          <Col md={3} lg={2} sm={12} className="mb-3 mx-auto">
            <Form.Label className="text-light px-2 my-auto mb-2">BEDROOMS: </Form.Label>

            <Form.Select aria-label="beds" onChange={handleBed} value={bed} style={{ width: "8rem" }} className="mx-auto">
              <option>Min Beds</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Select>
          </Col>

          <Col md={3} lg={2} sm={12} className="mb-3 mx-auto">
            <Form.Label className="text-light px-2 my-auto mb-2">BATHROOMS: </Form.Label>
            <Form.Select aria-label="baths" onChange={handleBath} value={bath} style={{ width: "8rem" }} className="mx-auto">
              <option>Min Baths</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Select>
          </Col>

          <Col md={4} sm={12} className="mx-auto text-center">
            <Button type="submit" variant="warning" className="mx-auto w-75 mt-2">
              SEARCH
            </Button>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  )
}

export default QueryForm
