import { useState, useEffect } from "react"
import axios from "axios"
import GetListings from "../components/GetListings"
import Header from "../components/Header"
import Loader from "../components/Loader"
import QueryForm from "../components/QueryForm"
import Pagination from "../components/Pagination"
import { baseUrl } from "../utils/baseUrl"


const Listings = () => {

  const [listings, setListings] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [currentPage, setCurrentPage] = useState(1)
  const [listPerPage] = useState(9)

  const [formData, setFormData] = useState('');
  const [beds, setBeds] = useState('');
  const [baths, setBaths] = useState('');


  const AllListings = async (dataToDisplay) => {
    const display = await dataToDisplay.map(obj => {
      const newMedia = obj.Media.filter(media => media.MediaCategory === "Photo")
      return { ...obj, Media: newMedia }
    })
    setListings(display) 
    return display
  }



  const getListings = async () => {
    const url = `${baseUrl}listings?City=${formData}&BedroomsTotal=${beds}&BathroomsFull=${baths}`
    setIsLoading(true)
    try {
      const { data } = await axios(url)
      AllListings(data)
    } catch (error) {}
    setIsLoading(false)
  }

  useEffect( () => {
    getListings()
  },[formData, beds, baths])

  const indexOfLastListing = currentPage * listPerPage
  const indexOfFirstListing = indexOfLastListing - listPerPage
  const currentListing = listings.slice(indexOfFirstListing, indexOfLastListing)


  const paginate = (pageNumber) => {
    return setCurrentPage(pageNumber)
  }

  const primaryText = "Melissa Shields"
  const secondaryText = "Bitterroot Valley & Beyond"
  const thirdText = "Real Estate Broker"
  return (
    <>
      <Header primary={primaryText} secondary={secondaryText} thirdText={thirdText} />
      <video muted autoPlay loop playsInline>
        <source src="video/background_video.mp4" type="video/mp4" />
      </video>

      {isLoading ? <Loader /> : <GetListings listings={currentListing} form={<QueryForm formData={formData} setFormData={setFormData} beds={beds} setBeds={setBeds} baths={baths} setBaths={setBaths} />} pages={<Pagination listPerPage={listPerPage} totalListing={listings.length} paginate={paginate} currentPage={currentPage} />} />}
    </>
  )
}

export default Listings
