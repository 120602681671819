import { Link } from "react-router-dom"

const Pagination = ({ listPerPage, totalListing, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalListing / listPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
    <nav>
      <ul className="pagination text-center mx-auto justify-content-center">
        {pageNumbers.map(num => (
            <li key={num} className="page-item ms-1 bg-warning rounded border-0">
              <Link onClick={() => paginate(num)} className="page-link text-dark fw-bold">
                {num}
              </Link>
            </li>
        ))}
      </ul>
      <p className="col-12 text-secondary fw-bold">{ `Page ${currentPage} of ${pageNumbers.length}` }</p>
    </nav>
  )
}

export default Pagination
