import React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom"
import App from "./App"
import Home from "./pages/Home"
import Listings from "./pages/Listings"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/styles/globalStyles.css"
import SingleListing from "./pages/SingleListing"
import About from "./pages/About"
import Resources from "./pages/Resources"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<Home />} />
      <Route path="/listings" element={<Listings />} />
      <Route path="/listing/:id" element={<SingleListing />} />
      <Route path="/about" element={<About />} />
      <Route path="/resources" element={<Resources />} />
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<RouterProvider router={router} />)
