import {Button, Spinner, Row, Col} from "react-bootstrap"

const Loader = () => {
  return (
    <>
      <Row className="border text-center vh-100 justify-content-center align-items-center">
        <Col>
          <Button variant="warning" disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span>Loading...</span>
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default Loader
