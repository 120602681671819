export const Reviews = [
  {
    id: "1",
    name: "Jessica Bain",
    review: "If you are looking a realtor who knows the area and will spend the time finding the perfect place to fit your needs, Melissa is definitely the one to call."
  },

  {
    id: "2",
    name: "Wendi Fawns",
    review: "Her ability to find and show the property to potential buyers was excellent and her ability to market the property helped make the sale of the property go smoothly in a timely manner. I recommend her without hesitation."
  },
  {
    id: "3",
    name: "Nicole Rainwater",
    review: "Melissa was so helpful when buying our first home. She made sure to listen to our needs and always kept our best interest in mind. I really felt that she had our backs throughout the entire process and was so generous with her time and finances."
  }
]