import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import axios from "axios"
import Header from "../components/Header"
import Loader from "../components/Loader"
import { Carousel, Col, Row, Accordion, ListGroup } from "react-bootstrap"
import { FaBed, FaBath, FaHome, FaDollarSign } from "react-icons/fa"
import {baseUrl} from '../utils/baseUrl'
import NotFound from "../components/NotFound"

const SingleListing = () => {
  const [listing, setListing] = useState([])
  const [images, setImages] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const { id } = useParams()

  useEffect(() => {
    const getListing = async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(`${baseUrl}/listing/${id}`)
        const { Media } = await data[0]
        const imageData = Media.filter(m => m.MediaType === "jpeg")
        setImages(imageData)
        setListing(data[0])
      } catch (error) {}
      setIsLoading(false)
    }
    getListing()
  }, [id])

  // While data is loading
  if (isLoading) {
    return <Loader />
  }

  const headerStyles = {
    background: `linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%), url(${images[Math.floor(Math.random() * images.length)].MediaURL})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  }

  const primaryText = listing.UnparsedAddress

  return (
    <>
      <Header style={headerStyles} primary={primaryText} />

      <Row className="property-wrap text-center">
        <Col md={12} className="mt-4">
          {listing.MlsStatus === "Active" ? <h1 className="text-success">{listing.MlsStatus}</h1> : listing.MlsStatus === "ActiveUnderContract" ? <h1 className="text-warning">{listing.MlsStatus.replace(/([A-Z])/g, " $1")}</h1> : <h1 className="text-light">{listing.MlsStatus}</h1>}
        </Col>
      </Row>

      <Row className="property-summary mx-auto justify-content-around">
        <Col className="text-center mt-4" lg={2}>
          <FaBed size={40} className="text-warning" />
          <p className="text-light mt-3">{listing.BedroomsTotal ? listing.BedroomsTotal : 0}</p>
        </Col>
        <Col className="text-center mt-4" lg={2}>
          <FaBath size={40} className="text-warning" />
          <p className="text-light mt-3">{listing.BathroomsFull ? listing.BathroomsFull : 0}</p>
        </Col>

        <Col className="text-center mt-4" lg={2}>
          <FaHome size={40} className="text-warning" />
          <p className="text-light mt-3">{listing.PropertyType}</p>
        </Col>
        <Col className="text-center mt-4" lg={2}>
          <FaDollarSign size={40} className="text-warning" />
          {listing.ListPrice ? <p className="text-white mt-3">{`$ ${listing.ListPrice.toLocaleString("ru-RU")}.00`}</p> : "No data"}
        </Col>
      </Row>

      <Row className="list-container text-center p-3">
        <h1>{`${listing.UnparsedAddress}, ${listing.City}`}</h1>
        <p>{listing.PublicRemarks}</p>
      </Row>

      <Col className="text-center mt-5 mb-5">
        <h2 className="text-center">Property Overview</h2>
      </Col>

      <Accordion className="mt-5 mb-5 w-75 mx-auto shadow shadow-lg">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Feature Highlights</Accordion.Header>
          <Accordion.Body>
            <ListGroup as="div">
              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-2">
                  <div className="fw-bold">Property Type</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.PropertySubTypeAdditional ? listing.PropertySubTypeAdditional.replace(/([A-Z])/g, " $1") : listing.PropertyType}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Bedrooms</div>
                </Col>

                <Col md={6} sm={12} className="p-3">
                  {listing.BedroomsTotal ? listing.BedroomsTotal : "0"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Full Bathrooms</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.BathroomsFull ? listing.BathroomsFull : "0"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Heating</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.Heating ? listing.Heating.replace(/([A-Z])/g, " $1") : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Cooling</div>
                </Col>
                <Col md={6} sm={6} className="p-3">
                  {listing.Cooling ? listing.Cooling.replace(/([A-Z])/g, " $1") : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <p>Appliances</p>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  <p>{listing.Appliances ? listing.Appliances.replace(/([A-Z])/g, " $1") : "---"}</p>
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Utilities</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.Utilities ? listing.Utilities.replace(/([A-Z])/g, " $1") : "0"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Total Living Area</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.LivingArea ? `${listing.LivingArea} ${listing.LivingAreaUnits}` : "---"}
                </Col>
              </Row>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Property Details</Accordion.Header>
          <Accordion.Body>
            <ListGroup as={"div"}>
              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Year Built</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.YearBuilt ? listing.YearBuilt : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Parking</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.ParkingFeatures ? listing.ParkingFeatures.replace(/([A-Z])/g, " $1") : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Water Source</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.WaterSource ? listing.WaterSource : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Fencing</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.Fencing ? listing.Fencing.replace(/([A-Z])/g, " $1") : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Construction Type</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.ConstructionMaterials ? listing.ConstructionMaterials.replace(/([A-Z])/g, " $1") : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Sewer Details</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.Sewer ? listing.Sewer.replace(/([A-Z])/g, " $1") : "---"}
                </Col>
              </Row>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Additional Information</Accordion.Header>
          <Accordion.Body>
            <ListGroup as={"div"}>
              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Lot Size (In Acres)</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.LotSizeAcres ? `${listing.LotSizeAcres} ${listing.LotSizeUnits}` : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Roof</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.Roof ? listing.Roof : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Zoning</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.Zoning ? listing.Zoning : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">County</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.CountyOrParish ? listing.CountyOrParish : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Listing Data</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.DaysOnMarketReplication ? `${listing.DaysOnMarketReplication} days` : "---"}
                </Col>
              </Row>

              <Row className="w-100 border mx-auto rounded">
                <Col md={6} sm={12} className="p-3">
                  <div className="fw-bold">Directions</div>
                </Col>
                <Col md={6} sm={12} className="p-3">
                  {listing.Directions}
                </Col>
              </Row>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Carousel variant="light" controls>
        {images ? images.map(img => (
          <Carousel.Item key={img.MediaKey}>
            <img className="w-100 d-block" src={img.MediaURL} alt={listing.UnparsedAddress} style={{ height: "700px", objectFit: "cover" }} />
          </Carousel.Item>
        )) : <NotFound />}
      </Carousel>
    </>
  )
}

export default SingleListing
