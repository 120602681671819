import { Link } from "react-router-dom"
import { Row, Col, Alert } from "react-bootstrap"
import { FaBed, FaShower, FaHome } from "react-icons/fa"
import NotFound from "./NotFound"
import { useState, useEffect } from "react"
import Global_Alternative from "./Global_Alternative"
const GetListings = props => {
  const data = props.listings
  const [listings, setListings] = useState([])
  const form = props.form
  const { pages } = props

  useEffect(() => {
    setListings(data)
  }, [data])

  return (
    <>
      <Row className="featured-properties-container mt-5 justify-content-center p-3">
        <h1 className="text-center text-light mb-5 ">Find Your Next Home!</h1>
        {listings.length > 0 ? form : null}
        {listings.length > 0 ? (
          listings.map(listing => (
            <Col key={listing.ListingId} sm={12} md={6} lg={5} xl={4} className="mt-3 text-center mb-5">
              <Col className="bg-light rounded mx-auto" md={12} lg={8}>
                {listing.Media[2] ? (
                  <img className="shadow" src={listing.Media[2].MediaURL} alt={listing.StreetName} style={{ width: "100%", height: "20rem", objectFit: "cover" }} />
                ) : (
                  <Alert variant="danger" className="text-center fw-bold">
                    No Image Preview.
                  </Alert>
                )}

                <h3 className="mt-1">{`${listing.StreetNumber} ${listing.StreetName}, ${listing.City}`}</h3>
                {listing.MlsStatus === "Active" ? <p className="text-success border-bottom border-warning">{`${listing.MlsStatus.replace(/([A-Z])/g, " $1").trim()}`}</p> : <p className="text-warning border-bottom border-warning">{`${listing.MlsStatus.replace(/([A-Z])/g, " $1").trim()}`}</p>}
                <div className="text-center row mt-1">
                  <Col md={6}>
                    <FaBed size={30} />
                  </Col>
                  <Col md={6}>{listing.PropertyType === "Land" ? (listing.BedroomsTotal = "0") : listing.BedroomsTotal}</Col>
                </div>

                <div className="text-center row mt-1">
                  <Col md={6}>
                    <FaShower size={30} />
                  </Col>
                  <Col md={6}>{listing.PropertyType === "Land" ? (listing.BathroomsFull = "0") : listing.BathroomsFull}</Col>
                </div>

                <div className="text-center row mt-1">
                  <Col md={6}>
                    <FaHome size={30} />
                  </Col>
                  <Col md={6}>{listing.PropertyType}</Col>
                </div>

                <div className="text-center row">
                  <Col md={12}>
                    <Link to={`/listing/${listing.ListingKey}`} className="btn btn-warning mb-3 w-50 mx-auto mt-2 text-light fw-bold">
                      View More
                    </Link>
                  </Col>
                  <Col md={12} className="mb-3 fw-bold">{`List Price: $${listing.ListPrice.toLocaleString("ru-RU")}.00`}</Col>
                </div>
              </Col>
            </Col>
          ))
        ) : (
          <Global_Alternative />
        )}
        <Col md="12" className="text-center align-items-center p-3 mt-3">
          {listings.length > 0 ? pages : ""}
        </Col>
      </Row>
    </>
  )
}

export default GetListings
