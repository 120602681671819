import { Row, Col, Alert } from "react-bootstrap"

const NotFound = () => {
  return (
    <Row>
      <Col>
        <Alert variant="warning" className="text-center fw-bold">No Listings to display. Please come back again!</Alert>
      </Col>
    </Row>
  )
}

export default NotFound
