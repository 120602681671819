import { Row, Col, Container } from "react-bootstrap"

const Global_Alternative = () => {
  return (
    <Container>
      <Row className="mx-auto">
        <Col sm={12}  className="vh-100 mx-auto">
          <iframe className="shadow shadow-lg rounded rounded-lg"  src="https://mtr.mlsmatrix.com/Matrix/public/IDX.aspx?idx=ec22c7" style={iframeStyle}></iframe>
        </Col>
      </Row>
    </Container>
  )
}
const iframeStyle = {
  width: "100%",
  height: "100%",
  border: "none"
}
export default Global_Alternative
